import { useStaticQuery, graphql } from "gatsby";
import { resolveImage } from "@dataResolvers";

const query = graphql`
  query HeaderQuery {
    craft {
      globalSets(handle: "header", site: "*") {
        language
        ... on Craft_header_GlobalSet {
          image {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
            extension
          }
          link1 {
            text
            url
          }
          link2 {
            text
            url
          }
          link3 {
            text
            url
          }
          headerNewProductText
          headerNavList {
            ... on Craft_headerNavList_subNav_BlockType {
              type: typeHandle
              navHeader {
                url
                text
              }
              subnavList {
                ... on Craft_subnavList_BlockType {
                  heading
                  image {
                    width
                    height
                    hasFocalPoint
                    slug
                    status
                    title
                    focalPoint
                    url
                  }
                  newProducts
                  learnMore {
                    text
                    url
                  }
                  extraLinks {
                    ... on Craft_extraLinks_link_BlockType {
                      linkObject {
                        text
                        url
                      }
                    }
                  }
                  useIcons
                  icon {
                    ... on Craft_icon_icon_Entry {
                      title
                      iconOverride
                    }
                  }
                }
              }
            }
          }
          linkList {
            ... on Craft_linkList_link_BlockType {
              linkObject {
                url
                text
              }
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ craft }) => {
  const langs = {};

  craft.globalSets.forEach(lang => {
    const {
      image,
      link1,
      link2,
      link3,
      headerNavList,
      linkList,
      headerNewProductText,
    } = lang;

    langs[lang.language] = {
      logo: resolveImage(image),
      telephone: link1,
      dealers: link2,
      usa: link3,
      newProductText: headerNewProductText,
      center: headerNavList?.map(col => {
        const { type } = col || {};

        if (type === "subNav") {
          return {
            type,
            header: col?.navHeader,
            subnav: col?.subnavList?.map(sub => {
              return {
                heading: sub?.heading,
                image: resolveImage(sub?.image),
                newProducts: sub?.newProducts,
                learnMore: sub?.learnMore,
                extraLinks: sub?.extraLinks?.map(link => {
                  return link?.linkObject;
                }),
                useIcons: sub?.useIcons,
                icon: sub?.icon?.[0]?.iconOverride,
              };
            }),
          };
        }
        return {
          type,
          link: col?.linkobject,
        };
      }),
      right: linkList?.map(link => {
        return link?.linkObject;
      }),
    };
  });
  return langs;
};

const useData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
